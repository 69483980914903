import googleLogo from './logos/google_signin_no_border.svg';
import microsoftLogo from './logos/microsoft_signin_no_border.svg';
import synapseLogo from './logos/synapse_signin_no_border.svg';
import iddoLogo from './logos/iddo_signin_no_border.svg';
import rareMdLogo from './logos/raremd_signin.png';

const EXAPTIVE_PROVIDER_TYPE = 'exaptive';
const tryGetNonExaptiveProviderTypes = provider => {
  switch (provider.type) {
    case 'google':
      return {
        image: googleLogo,
        defaultButtonText: 'Sign in with Google',
      };
    case 'azure-ad':
      return {
        image: microsoftLogo,
        defaultButtonText: 'Sign in with Microsoft',
      };
    case 'synapse':
      return {
        image: synapseLogo,
        defaultButtonText: 'Sign in with Synapse',
      };
    case 'custom':
      return {
        image: provider.loginRoute.includes('raremd') ? rareMdLogo : iddoLogo,
        defaultButtonText: `Sign in with ${provider.name}`,
      };
    default:
      return null;
  }
};

const hasExaptiveProvider = (authProviders = []) =>
  authProviders.some(
    provider => provider?.type === EXAPTIVE_PROVIDER_TYPE && provider.enabled
  );

const nonExaptiveProviders = (authProviders = []) =>
  authProviders.filter(
    provider => provider?.type !== EXAPTIVE_PROVIDER_TYPE && provider.enabled
  );

const hasNonExaptiveProvider = (authProviders = []) =>
  !!nonExaptiveProviders(authProviders).length;

export {
  tryGetNonExaptiveProviderTypes,
  nonExaptiveProviders,
  hasExaptiveProvider,
  hasNonExaptiveProvider,
};
