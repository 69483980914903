import AuthProvider from './components/AuthProvider';

function AuthProviders({ inviteCode, nonExaptiveProviders, autoLogin }) {
  return nonExaptiveProviders.map(provider => (
    <AuthProvider
      key={provider.id}
      provider={provider}
      inviteCode={inviteCode}
      autoLogin={autoLogin}
    />
  ));
}

export default AuthProviders;
