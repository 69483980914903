import ErrorAlert from '~/components/ErrorAlert';
import { tryGetNonExaptiveProviderTypes } from '../../utils';
import { useEffect, useRef } from 'react';

function AuthProvider({ provider, inviteCode, autoLogin }) {
  const nonExpativeProvider = tryGetNonExaptiveProviderTypes(provider);
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoLogin) {
      inputRef.current.click();
    }
  }, [autoLogin]);

  if (!nonExpativeProvider) {
    return (
      <ErrorAlert
        errorMsg={`Auth Provider "${provider.type}" not supported.`}
      />
    );
  }

  const _provider = {
    ...provider,
    buttonText: provider.buttonText || nonExpativeProvider.defaultButtonText,
    image: nonExpativeProvider.image,
    loginRoute: inviteCode
      ? `${provider.loginRoute}?inviteCode=${inviteCode}`
      : provider.loginRoute,
  };

  return (
    <>
      {_provider.descriptionText && (
        <div className="mb-2 text-gray-600">{_provider.descriptionText}</div>
      )}
      <a
        className="mb-6 mt-6 w-full cursor-pointer shadow-[0_2px_5px_0_rgb(0,0,0,20%)]"
        href={_provider.loginRoute}
        data-testid="auth-provider-link"
        ref={inputRef}
      >
        <img
          alt={_provider.buttonText}
          className="h-[41px] w-[215px]"
          src={_provider.image}
        />
      </a>
    </>
  );
}

export default AuthProvider;
